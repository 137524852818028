.ant-upload.ant-upload-drag {
  @apply border-0;
}

.ant-upload.ant-upload-btn {
  @apply bg-inputs-background dark:bg-inputs-background-dark text-icons-primary dark:text-icons-primary-dark
    border-2 border-dashed border-inputs-border dark:border-inputs-border-dark transition-colors rounded-2xl;

  &:hover {
    @apply border-buttons-primary-on-hover dark:border-buttons-primary-on-hover-dark;
  }
}

.ant-upload-list-item {
  @apply bg-backgrounds-primary dark:bg-backgrounds-primary-dark text-foregrounds-primary dark:text-foregrounds-primary-dark !important;

  .ant-btn {
    @apply bg-transparent;
  }
}

.wen-avatar-uploader,
.wen-icon-uploader {
  .ant-upload,
  .ant-upload-list-picture-card-container {
    @apply rounded-full w-32 h-32;

    .ant-upload-list-item,
    .ant-upload-list-item-image,
    .ant-upload-list-item-info,
    .ant-upload-list-picture-card .ant-upload-list-item-info::before {
      @apply rounded-full;
    }
  }
}

.wen-banner {
  .ant-upload,
  .ant-upload-list-picture-card-container {
    @apply h-28 w-full;
  }
}
