.ant-slider,
.ant-slider:hover {
  .ant-slider-rail {
    @apply bg-foregrounds-separator dark:bg-foregrounds-separator-dark;
  }

  .ant-slider-track {
    @apply bg-foregrounds-tertiary dark:bg-foregrounds-tertiary-dark;
  }

  .ant-slider-handle {
    @apply bg-buttons-primary-enabled dark:bg-buttons-primary-enabled-dark border-buttons-primary-enabled dark:border-buttons-primary-enabled-dark;
  }
}
