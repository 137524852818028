.ant-input,
.ant-input-search,
.ant-input-affix-wrapper,
.ant-input-number-input {
  @apply bg-inputs-background dark:bg-inputs-background-dark text-foregrounds-primary dark:text-foregrounds-primary-dark text-base font-medium;

  &:disabled {
    @apply bg-[#f5f5f5] dark:bg-inputs-background-dark dark:border-tags-border-dark;
  }

  &.ant-input-status-error {
    @apply dark:bg-inputs-background-dark;
    background: inherit !important;
  }
}

.ant-input-group-addon {
  @apply bg-inputs-background dark:bg-inputs-background-dark text-foregrounds-primary dark:text-foregrounds-primary-dark;
}

input::placeholder,
textarea::placeholder {
  @apply text-foregrounds-placeholder dark:text-foregrounds-placeholder-dark;
}

.floating-title {
  .ant-form-item-label {
    display: none;
    opacity: 0;
  }

  .ant-input,
  .ant-picker,
  .ant-input-number-input {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  &.value {
    .ant-form-item-label {
      position: absolute;
      z-index: 1;
      margin-left: 24px;
      pointer-events: none;
      display: block;
      opacity: 1;

      label {
        font-size: 12px;
        color: grey;
      }
    }

    .ant-input,
    .ant-picker,
    .ant-input-number-input {
      line-height: 24px;
      padding-bottom: 8px;
      padding-top: 24px;
    }
  }
}

.floating-title:focus-within {
  .ant-form-item-label {
    position: absolute;
    z-index: 1;
    margin-left: 24px;
    pointer-events: none;
    display: block;
    opacity: 1;

    label {
      font-size: 12px;
      color: grey;
    }
  }

  .ant-input,
  .ant-picker,
  .ant-input-number-input {
    padding-bottom: 8px;
    padding-top: 24px;
  }
}
