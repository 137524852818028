.ant-drawer-title {
  @apply text-4xl font-bold mt-2;
}

.ant-drawer-close {
  @apply px-12 py-16;
}

.ant-drawer-right {
  .ant-drawer-content,
  .ant-drawer-content-wrapper {
    @apply lg:rounded-l-large;
  }
}

.ant-drawer-left {
  .ant-drawer-content,
  .ant-drawer-content-wrapper {
    @apply lg:rounded-r-3xl;
  }
}

.ant-drawer-bottom {
  .ant-drawer-content,
  .ant-drawer-content-wrapper {
    @apply rounded-t-3xl;
  }
}

.ant-drawer-header,
.ant-drawer-body {
  @apply bg-backgrounds-tertiary dark:bg-backgrounds-tertiary-dark;
}

.ant-drawer-content-wrapper.secondary {
  .ant-drawer-header,
  .ant-drawer-body {
    @apply bg-backgrounds-secondary dark:bg-backgrounds-secondary-dark;
  }
}
