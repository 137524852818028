body {
  @apply font-display;
  @apply font-light;

  font-smooth: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.uppercase {
  text-transform: uppercase;
}
