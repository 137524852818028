.ant-card {
  @apply bg-backgrounds-tertiary dark:bg-backgrounds-tertiary-dark text-foregrounds-primary dark:text-foregrounds-primary-dark;
}

.ant-card-head-title {
  @apply font-bold pb-0 text-foregrounds-primary dark:text-foregrounds-primary-dark;
}

.ant-card-meta-title {
  @apply font-bold text-xl text-foregrounds-primary dark:text-foregrounds-primary-dark;
}

.ant-card-meta-description {
  @apply font-medium text-sm;
}

.wen-card {
  @apply filter transition-all drop-shadow-none;
}

.wen-cards-wrapper {
  @apply w-full grid justify-between items-center gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6;
}

.wen-cards-wrapper-light {
  @apply w-full grid justify-between items-center gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-4;
}

.wen-card:hover {
  @apply drop-shadow-card;
}

.wen-card-highlight-title {
  @apply ml-2 text-xs font-medium text-foregrounds-secondary dark:text-foregrounds-secondary-dark;
}
