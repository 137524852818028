.ant-progress-inner {
  @apply bg-backgrounds-secondary dark:bg-backgrounds-secondary-dark;
}

.ant-progress-bg {
  @apply bg-accent-primary dark:bg-accent-primary-dark;
}

.ant-progress-text {
  @apply text-foregrounds-secondary dark:text-foregrounds-secondary-dark;
}
