a.link {
  @apply inline-block;
  @apply font-semibold;
  @apply text-sm;
  @apply text-red-400;

  &:hover,
  &:active,
  &:focus {
    @apply underline;
  }
}
