.ant-menu-horizontal > [nz-menu-item] {
  @apply inline-flex items-center justify-center py-5 px-0;
}

[nz-menu] > .ant-menu-item::after {
  border-bottom: 0;
}

[nz-menu] > .ant-menu-item-selected {
  @apply text-tabs-text-selected dark:text-tabs-text-selected-dark bg-tabs-selected dark:bg-tabs-selected-dark;
}

[nz-menu] > .ant-menu-item:hover:not(.ant-menu-item-selected) {
  @apply text-tabs-text-on-hover dark:text-tabs-text-on-hover-dark;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  @apply text-tabs-text-selected dark:text-tabs-text-selected-dark !important;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  @apply text-tabs-text-selected dark:text-tabs-text-selected-dark bg-tabs-selected dark:bg-tabs-selected-dark;
}

.ant-menu-item {
  @apply text-foregrounds-primary dark:text-foregrounds-primary-dark !important;
}

.ant-menu-horizontal > .ant-menu-item {
  @apply lg:text-icons-primary dark:text-tabs-text-selected-dark lg:dark:text-icons-primary-dark;
}

.ant-menu-vertical {
  @apply bg-transparent text-foregrounds-primary dark:text-foregrounds-primary-dark dark:bg-backgrounds-primary-dark;

  .ant-menu-submenu {
    @apply h-12 p-0 text-current lg:w-10 lg:h-10 rounded-3xl my-1;
  }

  .ant-menu-submenu-title {
    @apply flex items-center justify-center p-0 rounded-3xl;
  }

  .ant-menu-submenu-arrow {
    display: none;
  }
}
