.ant-alert {
  @apply bg-alerts-warning dark:bg-alerts-warning-dark;

  .ant-alert-icon {
    @apply text-icons-primary dark:text-icons-primary-dark;
  }

  .ant-alert-message {
    @apply text-foregrounds-primary dark:text-foregrounds-on-primary-dark font-bold text-lg;
  }

  .ant-alert-description {
    @apply text-foregrounds-primary dark:text-foregrounds-tertiary-dark font-medium text-base;
  }
}
