.ant-dropdown-menu {
  @apply bg-backgrounds-secondary dark:bg-backgrounds-secondary-dark;
}

.ant-dropdown-menu-item {
  @apply text-foregrounds-primary dark:text-foregrounds-primary-dark;
}

.ant-dropdown-menu-item:hover {
  @apply bg-accent-selected dark:bg-accent-selected-dark;
}
