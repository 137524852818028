.ant-modal-content {
  @apply bg-backgrounds-tertiary dark:bg-backgrounds-tertiary-dark text-foregrounds-primary dark:text-foregrounds-primary-dark;
}

.ant-modal-header {
  @apply bg-backgrounds-tertiary dark:bg-backgrounds-tertiary-dark text-foregrounds-primary dark:text-foregrounds-primary-dark;
}

.wen-transaction-select-container {
  @apply flex flex-col justify-between h-full mt-10 lg:items-center lg:justify-start;
}

.wen-transaction-active-container {
  @apply flex flex-col items-center justify-between h-full mt-10 lg:justify-start;
}

.wen-transaction-wait-container {
  @apply flex flex-col items-center justify-between h-full lg:justify-start;
}

.wen-modal-block-title {
  @apply px-5 pt-3 pb-2 text-sm font-bold border-b;
}

.wen-modal-history-log {
  @apply h-32 px-5 pt-4 pb-2 mr-2 overflow-y-scroll;
}

.wen-modal-history-log-item {
  @apply relative flex items-center my-1;
}

.wen-modal-tabs {
  @apply absolute bottom-0 w-full top-56 lg:static;
}

.wen-modal-header {
  @apply flex justify-between items-center;
  // Additional styling if needed
}
