.ant-select-selector {
  @apply min-h-10 bg-backgrounds-tertiary dark:bg-backgrounds-tertiary-dark !important;

  .ant-select-selection-search {
    @apply flex justify-center items-center;
  }

  .ant-select-selection-placeholder {
    @apply font-medium text-base;
  }

  .ant-select-selection-item {
    @apply flex items-center lg:mr-3 text-foregrounds-primary dark:text-foregrounds-primary-dark;
  }
}

.ant-select-dropdown {
  @apply bg-backgrounds-tertiary dark:bg-backgrounds-tertiary-dark;

  .ant-select-item-option {
    @apply text-foregrounds-primary dark:text-foregrounds-primary-dark;
  }

  .ant-select-item-option-selected,
  .ant-select-item-option:hover,
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    @apply bg-accent-selected dark:bg-accent-selected-dark;
  }
}

.wen-select-min-height {
  .cdk-virtual-scroll-viewport {
    min-height: 100px;
  }
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  @apply bg-[#f5f5f5] dark:bg-inputs-background-dark dark:border-tags-border-dark !important;
}
